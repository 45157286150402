import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { arrayMove } from "@dnd-kit/sortable";
import { useDebouncedCallback } from "use-debounce";

import { handleNetworkError } from "../../utils/api";
import useCustomFields from "../../hooks/useCustomFields";
import useMutateCustomField from "../../hooks/useMutateCustomField";

import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import EditableField from "../../common/EditableField/EditableField";
import Sortable from "../../common/Sortable/Sortable";
import Button from "../../common/Button/Button";
import Form from 'react-bootstrap/Form';

import TrashIcon from "../../assets/icons/TrashIcon";

import './CustomFieldsManager.scss';

export default function CustomFieldsManager({ categoryId, showSavedToast }) {
  const [fieldsOrder, setFieldsOrder] = useImmer(null);
  const [showCreate, setShowCreate] = useState(false);
  const [createCode, setCreateCode] = useState('');
  const [createLabel, setCreateLabel] = useState(undefined);
  const [creating, setCreating] = useState(false);
  const [createErrors, setCreateErrors] = useState(false);
  const [createValidated, setCreateValidated] = useState(false);
  const [createdId, setCreatedId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const { data: customFields, isLoading } = useCustomFields({ categoryId });
  const { createCustomField, deleteCustomField, bulkUpdateCustomFieldAsync } = useMutateCustomField({ categoryId });

  const addRef = useRef();
  const newItemRef = useRef();

  useEffect(() => {
    const order = customFields?.sort((a, b) => a.order_index - b.order_index).map(field => ({ id: field.id }));
    order && setFieldsOrder(order);
  }, [customFields, setFieldsOrder]);

  useLayoutEffect(() => {
    if (showCreate) {
      const rect = addRef.current.getBoundingClientRect();

      if (rect.bottom > window.innerHeight || rect.top < 0) {
        addRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [showCreate]);

  useLayoutEffect(() => {
    if (createdId) {
      newItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [createdId]);

  const debouncedRemoveCreatedStatus = useDebouncedCallback(() => {
    setCreatedId(null);
  }, 2000);

  if (isLoading || !fieldsOrder) {
    return null;
  }

  const stringToCode = value => value.toLowerCase().replace(/^(_|\d|\W)/, '').replaceAll(/(\s|-)/g, '_').replaceAll(/\W/g, '');

  const onChangeCode = e => {
    setCreateCode(stringToCode(e.target.value));
  }

  const onCreate = e => {
    e.preventDefault();

    setCreating(true);
    setCreateErrors(false);
    setCreateValidated(false);

    const lastIndex = customFields.find(item => item.id === fieldsOrder[fieldsOrder.length - 1]?.id)?.order_index || 0;

    const data = {
      category_id : categoryId,
      code        : createCode,
      label       : createLabel,
      order_index : lastIndex + 1,
    }

    const onSettled = () => {
      setCreating(false);
    }

    const onSuccess = newStatus => {
      setShowCreate(false);
      setCreatedId(newStatus.id);
      debouncedRemoveCreatedStatus();
    }

    const onError = e => {
      handleNetworkError({ e, setErrors: setCreateErrors, setValidated: setCreateValidated });
    }

    createCustomField.mutate({ data }, { onSettled, onSuccess, onError });
  }

  const onDelete = id => {
    setDeleteId(id);
    setShowDelete(true);
  }

  const confirmDelete = () => {
    deleteCustomField.mutate(deleteId, { onSuccess: () => showSavedToast('Custom field deleted') });
    setShowDelete(false);
  }

  const onChangeOrder = (activeIndex, overIndex) => {
    const lowestIndex = Math.min(activeIndex, overIndex);
    const highestIndex = Math.max(activeIndex, overIndex);

    const newOrder = arrayMove(fieldsOrder, activeIndex, overIndex);

    const data = [];

    for (let i = lowestIndex; i <= highestIndex; ++i) {
      const item = customFields.find(item => item.id === newOrder[i].id);
      data.push({ id: item.id, order_index: i });
    }

    bulkUpdateCustomFieldAsync.mutate({ data, categoryId });
    setFieldsOrder(newOrder);
  }

  const toggleAdd = () => {
    setShowCreate(true);
      
    setCreateCode('');
    setCreateLabel('');
    setCreateErrors(null);
    setCreateValidated(false);
  }

  const onEditSucces = () => {
    showSavedToast('Lead status updated')
  }

  const renderItem = item => {
    const customField = customFields.find(field => item.id === field.id);

    // when deleting a status, the data might be insconsistent, delete if no status found
    if (!customField) return;

    const newlyCreated = createdId === customField.id;

    return (
      <Sortable.Item id={customField.id} newlyCreated={newlyCreated} columnView>
        <div className="row-handle" ref={newItemRef}><Sortable.DragHandle /></div>
        <div className="row-code">
          <EditableField field='code' customValue={stringToCode} initialValue={customField.code} id={customField.id} onEditSucces={onEditSucces} mutation={useMutateCustomField} mutationData={{ categoryId }} updateName='updateCustomField' />
        </div>
        <div className="row-label">
          <EditableField field='label' initialValue={customField.label} id={customField.id} onEditSucces={onEditSucces} mutation={useMutateCustomField}  mutationData={{ categoryId }} updateName='updateCustomField' />
        </div>
        <div className="row-delete">
          <button className='delete-btn' onClick={() => onDelete(customField.id)}><TrashIcon /> Remove</button>
        </div>
      </Sortable.Item>
    );
  }

  return (
    <div className="__custom-fields-manager">
      <div className="title-actions">
        <h2>Custom Fields</h2>
        { !showCreate && <button className="main-action" onClick={toggleAdd}>＋ Add Custom Field</button> }
      </div>

      { showCreate && (
        <div className="add-field" ref={addRef}>
          <Form onSubmit={onCreate} noValidate validated={createValidated && !createErrors}>
            <div className="field-container code">
              <Form.Label>Code</Form.Label>
              <Form.Group className="form-group">
                <Form.Control autoFocus value={createCode} maxLength={64} onChange={onChangeCode} isValid={createValidated && !createErrors?.code} isInvalid={createErrors?.code} />
                { createErrors?.code?.map(error => <Form.Control.Feedback key={error} type='invalid'>{ error }</Form.Control.Feedback> )}
              </Form.Group>
              <div className="code-description">Field code must start with a letter, and can only contain letters, underscores and numbers.</div>
            </div>

            <div className="field-container">
              <Form.Label>Label</Form.Label>
              <Form.Group className="form-group">
                <Form.Control value={createLabel || ''} maxLength={64} onChange={e => setCreateLabel(e.target.value)} isValid={createValidated && !createErrors?.label} isInvalid={createErrors?.label} />
                { createErrors?.label?.map(error => <Form.Control.Feedback key={error} type='invalid'>{ error }</Form.Control.Feedback> )}
              </Form.Group>
              <div className="actions">
                <Button disabled={creating} className='cancel' type='button' onClick={() => setShowCreate(false)}>Cancel</Button>
                <Button loading={creating} type='submit'>Create</Button>
              </div>
            </div>
            { createErrors?.non_field_errors?.map(error => <div className="request-error" key={error}>{ error }</div> )}
          </Form>
        </div>
      )}

      { customFields?.length > 0
      ? <div className="fields-table">
          <div className="table-head">
            <div className="row-handle" />
            <div className="row-code">Code</div>
            <div className="row-label">Label</div>
            <div className="row-delete" />
          </div>
          <Sortable items={fieldsOrder} onChange={onChangeOrder} renderItem={renderItem} columnView />
        </div>
      : (!showCreate && <div className='empty-state'>Custom fields allow you to add extra fields for your leads. <a href="#learn-more">Learn more</a>.</div> )}

      <ConfirmationModal show={showDelete} title='Delete Custom Field' confirmText='Delete' onHide={() => setShowDelete(false)} onConfirm={confirmDelete}>
        <p>Are you sure you want to delete this custom field?</p>
        <p>Leads that have this custom field will still display the field code.</p>
      </ConfirmationModal>
    </div>
  );
}
