import React, { useEffect, useId, useState } from 'react';
import { useImmer } from 'use-immer';
import Papa from 'papaparse';
import classNames from 'classnames';
import LEAD_FIELDS, { IMPORT_FIELD_SYNONYMS, IMPORT_FIELDS, NAME_FIELDS } from '../../utils/leadFields';

import useGlobalParams from '../../hooks/useGlobalParams';
import useUsers from '../../hooks/useUsers';
import useCustomFields from '../../hooks/useCustomFields';
import useMutateLead from '../../hooks/useMutateLead';
import useStatuses from '../../hooks/useStatuses';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from '../Button/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import LeadStatusDropdown from '../../routes/Leads/LeadStatusDropdown';
import LeadAssigneeDropdown from '../../routes/Leads/LeadAssigneeDropdown';
import AddLeadStatusModal from './AddLeadStatusModal';
import LeadDistributionModal from '../../routes/Settings/LeadDistributionModal';

import { ReactComponent as UploadIcon } from '../../assets/icons_svg/upload.svg';
import { ReactComponent as CSVFileIcon } from '../../assets/icons_svg/csv-file.svg';
import { ReactComponent as TXTFileIcon } from '../../assets/icons_svg/txt-file.svg';
import { ReactComponent as UnknownFileIcon } from '../../assets/icons_svg/unknown-file.svg';
import ChevronIcon from "../../assets/icons/ChevronIcon";

import './ImportLeadsModal.scss';

const STEPS = ['upload', 'header', 'columns', 'options'];

const LEAD_DISTRIBUTION = {
  unassigned: {
    label       : "Unassigned",
    description : "Imported leads will not be assigned to any user",
  },
  one_user: {
    label       : "Assign to one user",
    description : "All Imported leads will be assigned to one user that you choose",
  },
  automatic: {
    label       : "Automatic",
    description : "Leads are randomly assigned evenly between all users in this category",
  },
  custom: {
    label       : "Custom",
    description : "Imported leads will be assigned based on a user-defined distribution",
  },
}

export default function ImportLeadsModal({ show, onHide, ...props }) {

  const { organizationId, categoryId } = useGlobalParams();

  const { importMutation } = useMutateLead();
  const { data: customFields } = useCustomFields({ categoryId });
  const { data: statuses } = useStatuses({ categoryId });
  const { data: users } = useUsers({ organizationId, categoryId });

  const [step, setStep] = useState(STEPS[0]);
  const [file, setFile] = useState(null);
  const [parsedRows, setParsedRows] = useState(null);
  const [headerRow, setHeaderRow] = useState(0);
  const [importColumns, setImportColumns] = useState([]);
  const [importMatch, setImportMatch] = useImmer([]);
  const [importSelected, setImportSelected] = useImmer([]);
  const [customMatches, setCustomMatches] = useImmer([]);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDistribution, setSelectedDistribution] = useState('unassigned');
  const [selectedUser, setSelectedUser] = useState(null);
  const [keepDuplicates, setKeepDuplicates] = useState(true);
  const [showDistribution, setShowDistribution] = useState(false);
  const [customDistribution, setCustomDistribution] = useState(null);

  const fileInputId = useId();

  useEffect(() => {
    if (show) {
      setStep(STEPS[0]);
      setFile(null);
      setSelectedStatus(null);
      setSelectedDistribution('unassigned');
      setSelectedUser(null);
      setCustomDistribution(null)
      setKeepDuplicates(true);
    }
    else {
      // clear the file on modal close
      setFile(null);
    }
  }, [show]);

  const onDropFile = e => {
    e.preventDefault();

    if (e.dataTransfer.items[0]) {
      const item = e.dataTransfer.items[0];

      if (item.kind === "file") {
        const file = item.getAsFile();
        onUpload(file);
      }
    }
    else {
      onUpload(e.dataTransfer.files[0]);
    }
  }

  const onDragOver = e => {
    e.preventDefault();
  }

  const onChangeFile = e => {
    onUpload(e.target.files[0]);
  }

  const onCreateStatus = newId => {
    setSelectedStatus(newId);
  }

  const onUpload = uploadFile => {
    if (!uploadFile) {
      return;
    }

    setFile(uploadFile);

    const fileSlice = uploadFile.slice(0, 10000);
    
    const reader = new FileReader();

    reader.onloadend = e => {
      if (e.target.readyState === FileReader.DONE) {
        const text = e.target.result;
        const parsedValues = Papa.parse(text, { preview: 32 });
        const maxLength = parsedValues.data.reduce((max, row) => Math.max(max, row.length), 0);
        setParsedRows(parsedValues.data.map(row => row.length === maxLength ? row : [...row, ...Array(maxLength - row.length).fill(' ')]));
        setHeaderRow(0);
      }
    }

    reader.readAsText(fileSlice);
  }

  const onBack = () => {
    const index = STEPS.findIndex(s => s === step);
    if (index === 0) {
      onHide();
    }
    else {
      setStep(STEPS[index - 1]);
    }
  }

  const onNext = () => {
    switch (step) {
      case 'upload':
        setStep('header');
        break;

      case 'header':
        const columns = [];
        const matches = [];

        parsedRows[headerRow].forEach(column => {
          // remove spaces, set to lowercase, and replace special characters or spaces with underscores
          // IE: número de teléfono -> n_mero_de_tel_fono
          const sanitizedColumn = column.trim().toLowerCase().replaceAll(/\W/g, '_');
          columns.push(column.trim());

          // try to find the field inside the valid imports, the synonym list, or a possible custom code field:
          let match = IMPORT_FIELDS.find(column => column === sanitizedColumn) || IMPORT_FIELD_SYNONYMS[sanitizedColumn] || customFields.find(field => field.code === sanitizedColumn)?.code;

          // if it matches an import column, and it hasn't been added, add it to matches array:
          if (match && !matches.find(column => column === match)) {
            matches.push(match);
          }
          else {
            matches.push(null);
          }
        });

        setImportColumns(columns);
        setImportMatch(matches);
        setImportSelected(new Array(columns.length).fill(true));

        setStep('columns');
        break;

      case 'columns':
        const filterMatches = [];
        let newName;
        let copyValue;

        const findNewName = column => column === newName;

        importMatch.forEach((match, index) => {
          // push null for columns that are not selected:
          if (!importSelected[index]) {
            filterMatches.push(null);
          }
          // if the column is a match, push it directly:
          else if (match) {
            filterMatches.push(match);
          }
          // if it's null, a custom column will need to be created:
          else {
            // sanitize the name:
            const sanitizedColumn = importColumns[index].trim().toLowerCase().replaceAll(/\W/g, '_');

            // if the custom column exists in matches, or it's already in the importMatch (filtering the current item), add a value in front of it, ie: phone_2, phone_3 etc:
            if (filterMatches.find(column => column === sanitizedColumn) || importMatch.filter((_, i) => i !== index).find(column => column === sanitizedColumn) || IMPORT_FIELDS.find(field => field === sanitizedColumn)) {
              copyValue = 2;
              newName = `${sanitizedColumn}_${copyValue}`;
              
              while (filterMatches.find(findNewName) || importMatch.filter((_, i) => i !== index).find(findNewName)) {
                newName = `${sanitizedColumn}_${copyValue}`;
                ++copyValue;
              }
              
              filterMatches.push(newName);
            }

            // column didn't exist, add the sanitized column value as a custom match:
            else {
              filterMatches.push(sanitizedColumn);
            }
          }
        });

        setCustomMatches(filterMatches);
        setStep('options');
        break;
        
      case 'options':
        onSubmit();
        break;

      default:
        console.warn("no step set", step);
    }
  }

  const onSubmit = () => {
    const onSuccess = () => {
      onHide();
    }

    const reader = new FileReader();
    
    const headerColumns = customMatches.filter(item => item !== null);
    const columnData = [headerColumns];

    const step = ({ data }) => {
      if (data.length > 0) {
        const rowData = data.filter((_, index) => customMatches[index] !== null);

        // empty strings '' are added so that the missing values don't get parsed as NULL in the backend, instead as empty strings:
        columnData.push(rowData.length >= headerColumns.length ? rowData : [...rowData, ...Array(headerColumns.length - rowData.length).fill('')]);
      }
    }
    
    const complete = () => {
      const csvBlob = new Blob([Papa.unparse(columnData)], { type: 'text/csv' });

      const formData = new FormData();
      
      formData.append("file", csvBlob, 'text.csv');
      formData.append("category_id", categoryId);
      formData.append("status_id", selectedStatus);
      selectedDistribution === 'one_user' && formData.append("assigned_user_id", selectedUser);

      importMutation.mutate({ formData, categoryId }, { onSuccess });
    }
    
    reader.onloadend = e => {
      if (e.target.readyState === FileReader.DONE) {
        const text = e.target.result;
        Papa.parse(text, { skipFirstNLines: headerRow + 1, step, complete });
      }
    }
    
    reader.readAsText(file);

    return;
  }

  const changeColumnMatch = (index, field) => {
    setImportMatch(draft => {
      if (field !== null) {
        // if another column already had that field, remove it:
        const repeatedIndex = draft.findIndex(column => column === field);
  
        if (repeatedIndex !== -1) {
          draft[repeatedIndex] = null;
        }
      }

      draft[index] = field;
    });
  };

  const changeColumnSelected = (e, index) => {
    setImportSelected(draft => {
      draft[index] = e.target.checked;
    });
  }

  const onSaveDistribution = distribution => {
    setCustomDistribution(distribution);
    setSelectedDistribution('custom');
    setShowDistribution(false);
  }
  
  const renderStep = () => {
    switch (step) {
      case 'upload':
      default:
        return renderUploadFile();

      case 'header':
        return renderChooseHeader();

      case 'columns':
        return renderColumns();

      case 'options':
        return renderOptions();
    }
  }

  const fileSize = () => {
    if (file.size < 1024) {
      return `${file.size} bytes`;
    }
    else if (file.size < 1024 * 1024) {
      return `${Math.floor(file.size / 1024)} KB`;
    }
    else {
      return `${(file.size / (1024 * 1024)).toFixed(1)} MB`;
    }
  }

  const fileType = () => {
    if (file.type.includes("/csv")) {
      return "CSV File";
    }
    else if (file.type === 'text/plain') {
      return "Text File"
    }
    else {
      return "Unsupported Format";
    }
  }

  const renderFileIcon = () => {
    if (file.type.includes("/csv")) {
      return <CSVFileIcon className='file-icon' />
    }
    else if (file.type === 'text/plain') {
      return <TXTFileIcon className='file-icon' />
    }
    else {
      return <UnknownFileIcon className='file-icon' />
    }
  }

  const renderFileInfo = () => (
    <div className='file-box'>
      <div className='file-info'>
        { renderFileIcon() }
        <div className='file-name-size'>
          <div className='file-name'>{ file.name }</div>
          <div className='file-size'>{ fileType() } - { fileSize() }</div>
        </div>
        <button className='delete-file' onClick={() => setFile(null)}>✕</button>
      </div>
    </div>
  )

  const renderUploadContent = () => (
    <Form.Group controlId={fileInputId} className='upload-file'>
      <Form.Label className='upload-box'>
        <UploadIcon className='upload-icon' />
        <h3>Import CSV File</h3>
        <div>Drag inside or click to upload</div>
      </Form.Label>
      <Form.Control type='file' onChange={onChangeFile} />
    </Form.Group>
  );

  const renderUploadFile = () => (
    <>
      <p className='description'>Precious Leads can import CSV files generated by spreadsheet software or other CRMs. <a href='#WIP'>Learn more about importing files.</a></p>
      { file ? renderFileInfo() : renderUploadContent() }
    </>
  );

  const renderChooseHeader = () => (
    <>
      <p className='description'>Choose the row with the header fields (name, email, etc) that will be used for the import. Any row above it will be ignored when importing data. <a href="#WIP">Learn more</a></p>

      <div className='header-container'>
        <table className='header-table'>
          <tbody>
            { parsedRows?.map((row, index) => (
              <tr key={index} role='button' className={classNames({ selected: index === headerRow, ignored: index < headerRow })} onClick={() => setHeaderRow(index)}>
                <td className='toggle'>{ index + 1 }</td>
                { row.map((cell, index) => <td key={index} title={cell}>{ cell || '\u00A0' }</td> )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Form.Group className='header-row-input'>
        <Form.Label>Header row: </Form.Label>
        <Form.Control type='number' autoFocus value={headerRow + 1} onChange={e => setHeaderRow(Math.max(Math.min(parsedRows.length - 1, e.target.value - 1), 0))} />
      </Form.Group>
    </>
  )

  const renderColumns = () => (
    <>
      <p className='description'>Select the columns you would like to import.  Match the desired column, otherwise leave it unassigned and custom fields will be automatically created.</p>

      <div className='columns-table'>
        <div className='table-head'>
          <div className='toggle-column' />
          <div className='imported-column'>Imported Field</div>
          <div className='match-column'>Match Field</div>
        </div>

        { importColumns.map((column, index) => (
          <div key={index} className='table-row'>
            <div className='toggle-column'><Form.Check checked={importSelected[index]} onChange={e => changeColumnSelected(e, index)} /></div>
            <div className={classNames('imported-column', {'empty-field': !column.trim?.().length })}>{ column || `(Empty field)` }</div>
            <div className='match-column'>
              { importSelected[index] ? (
                <Dropdown className='import-dropdown'>
                  <Dropdown.Toggle as={Toggle} className='import-toggle'>
                    <div className="toggle-overflow">
                      <div className='label'>{ NAME_FIELDS[importMatch[index]] || LEAD_FIELDS[importMatch[index]]?.label || customFields?.find(field => field.code === importMatch[index])?.label || <div className='label-placeholder'>Select a column...</div> }</div>
                      <ChevronIcon className='chevron' />
                    </div>
                    
                  </Dropdown.Toggle>
                  <Dropdown.Menu renderOnMount popperConfig={{ strategy: 'fixed' }}>
                    <div className='field-columns'>
                      <div>
                        { customFields?.length > 0 && <Dropdown.Header>Default Fields</Dropdown.Header> }
                        { IMPORT_FIELDS.map(field => (
                          <Dropdown.Item key={field} onClick={() => changeColumnMatch(index, field)} active={importMatch[index] === field}>{ NAME_FIELDS[field] || LEAD_FIELDS[field].label }</Dropdown.Item>
                        ))}
                        <Dropdown.Item className='reset-column' onClick={() => changeColumnMatch(index, null)} active={importMatch[index] === null}>Unassigned field</Dropdown.Item>
                      </div>
                      { customFields?.length > 0 && (
                        <div>
                          <Dropdown.Header>Custom Fields</Dropdown.Header>
                          {customFields.map(({ code, label }) => (
                            <Dropdown.Item key={code} onClick={() => changeColumnMatch(index, code)} active={importMatch[index] === code}>
                              <div className='label'>{ label }</div>
                            </Dropdown.Item>
                          ))}
                        </div>
                      )}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div className='unselected-field'>Column will be ommited on import</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );

  const renderOptions = () => (
    <>
      <p className='description'>Choose the status for newly imported leads and the user assignment distribution:</p>

      <div className='option-dropdown'>
        <div className='option-description'>Import newly added leads to the status:</div>
        <LeadStatusDropdown type='large' statuses={statuses} selectedStatusId={selectedStatus} showCreate onCreate={() => setShowAddCategory(true)} onChange={id => setSelectedStatus(id)} placeholder='Select a status...' />
      </div>

      <div className='option-dropdown'>
        <div className='option-description'>Imported lead distribution:</div>
        <Dropdown className='__lead-dropdown __lead-dropdown-large distribution-dropdown'>
          <Dropdown.Toggle as={Toggle} className='lead-toggle'>
            <div className='toggle-overflow'>
              <div className='label'>{ LEAD_DISTRIBUTION[selectedDistribution].label }</div>
              <ChevronIcon className='chevron' />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            { Object.entries(LEAD_DISTRIBUTION).map(([key, value]) => (
              <Dropdown.Item key={key} onClick={() => key === 'custom' ? setShowDistribution(true) : setSelectedDistribution(key)} active={selectedDistribution === key}>
                <div className='dropdown-value'>{ value.label }</div>
                <div className='dropdown-description'>{ value.description }</div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        { selectedDistribution === 'custom' && (
          <div className='option-edit'>
            <button onClick={() => setShowDistribution(true)}>Edit distribution</button>
          </div>
        )}
      </div>
      
      { selectedDistribution === 'one_user' && (
        <div className='option-dropdown'>
          <div className='option-description'>Assign imported leads to the following user:</div>
          <LeadAssigneeDropdown users={users} selectedUserId={selectedUser} onChange={id => setSelectedUser(id)} type='large' hideUnassigned  placeholder='Select a user...' />
        </div>
      )}

      { selectedDistribution !== 'unassigned' && (
        <>
          <Form.Check checked={keepDuplicates} label='Keep originally assigned user on duplicates' onChange={e => setKeepDuplicates(e.target.checked)} />
          <p className='description distribution-description'>
            When checked, if an imported lead is duplicated and was originally assigned to another user, the duplicated lead will stay assigned to the original user
          </p>
        </>
      )}

    </>
  );

  const confirmDisabled = () => !file || (step === 'options' && (!selectedStatus || (selectedDistribution === 'one_user' && !selectedUser)));

  return (
    <>
      <Modal className='__import-leads-modal __modal' show={show && !showAddCategory && !showDistribution} backdrop='static' centered size='lg' {...props} onDrop={onDropFile} onDragOver={onDragOver} scrollable={step !== 'options'}>
        <Modal.Header closeButton onHide={onHide}><h2>Import Leads</h2></Modal.Header>
        <Modal.Body className={`step-${step}`}>
          { renderStep() }
        </Modal.Body>
        <Modal.Footer>
          <Button className='cancel' onClick={onBack}>{ step === STEPS[0] ? 'Cancel' : 'Back' }</Button>
          <Button className='confirm' onClick={onNext}  disabled={confirmDisabled()}>{ step === 'options' ? 'Import' : 'Next' }</Button>
        </Modal.Footer>
      </Modal>
      <AddLeadStatusModal show={showAddCategory} statuses={statuses} onHide={() => setShowAddCategory(false)} onCreateSuccess={onCreateStatus} />
      <LeadDistributionModal show={showDistribution} customDistribution={customDistribution} onHide={() => setShowDistribution(false)} onSave={onSaveDistribution} />
    </>
  );
}

const Toggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props}>
    { children }
  </button>
));
