import { useInfiniteQuery } from "@tanstack/react-query";
import { api } from "../utils/api";

const getLeads = async ({ categoryId, ordering, filters, pageParam, editedLeads }) => {
  if (pageParam) {
    const res = await api.get(pageParam);

    // Filter out leads that already exist (IE: if the user edited their email, but the next pagination fetches it because of the ordered-by)
    return {
      data    : editedLeads?.size > 0 ? res.data.filter(lead => !editedLeads.has(lead.id)) : res.data,
      nextUrl : res.headers['x-next-url'],
    }
  }


  const params = new URLSearchParams();
  params.append('page_size', 25);

  if (ordering) {
    params.append('ordering', ordering);
  }

  filters?.forEach(filter => {
    params.append(filter.field + (filter.match === 'contains' ? '_contains' : ''), filter.value);
  });

  const res = await api.get(`/leads/?category_id=${categoryId}&${params.toString()}`);

  return { data: res.data, nextUrl: res.headers['x-next-url'] }
}

export default function useLeads({ business, categoryId, ordering, filters, editedLeads, ...props}) {
  return useInfiniteQuery({ queryKey: ['leads', categoryId, ordering || 'none', filters || 'none'], queryFn: ({ pageParam }) => getLeads({ categoryId, ordering, filters, pageParam, editedLeads }), ...props, getNextPageParam: lastPage => lastPage?.nextUrl !== 'None' ? lastPage.nextUrl : null });
}
