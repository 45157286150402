import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../utils/api";
import { produce } from "immer";

const normalizeBulkIds = ids => ids.map(id => ({ id }));

const addLead = ({ data }) => api.post(`/leads/`, { ...data }).then(res => res.data);
const updateLead = ({ id, data }) => api.put(`/leads/${id}/`, { ...data }).then(res => res.data);
const undoDeleteLead = ({ lead }) => api.put(`/leads/${lead.id}/restore/`).then(res => res.data);
const deleteLead = id => api.delete(`/leads/${id}/`).then(res => res.data);
const deleteBulk = ids => api.delete(`/leads/`, { data: normalizeBulkIds(ids) }).then(res => res.data);
const importLeads = ({ formData }) => api.post(`/leads/import/`, formData).then(res => res.data);

export default function useMutateLead() {
  const queryClient = useQueryClient();

  const findLead = (id, draft) => {
    for (let page of draft.pages) {
      const index = page.data.findIndex(lead => lead.id === id);
      
      if (index !== -1) {
        const lead = page.data[index];
        return { page, lead, index }
      }
    }
  }

  const onMutateLead = variables => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      const lead = findLead(variables.id, draft);
      
      if (lead) {
        lead.page.data[lead.index] = { ...lead.lead, ...variables.data };
      }
    }));
  }

  const onUpdateSuccess = (requestData, { id }) => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      const lead = findLead(id, draft);
      
      if (lead) {
        lead.page.data[lead.index] = requestData;
      }
    }));
  }

  const onAddLead = requestData => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      // add the lead to the first page in the pagination:
      draft.pages[0].data.unshift(requestData);
    }));
  }

  const onBulkDelete = ids => {
    console.log("TODO -> BULK NEEDS TO SET EACH PAGE INDIVIDUALLY!");
    queryClient.setQueriesData(['leads'], data => produce(data, draft => draft.filter(lead => !ids.includes(lead.id))));
  }

  const onDelete = id => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      const lead = findLead(id, draft);

      if (lead) {
        lead.page.data.splice(lead.index, 1);
      }
    }));
  }

  const onUndoDelete = ({ lead, pageIndex, index }) => {
    queryClient.setQueriesData(['leads'], data => produce(data, draft => {
      draft.pages[pageIndex].data.splice(index, 0, lead);
    }));
  }

  const onImport = (responseData) => {
    const { import_task } = responseData;
    // BUG -> https://github.com/acalderon85/preciousleads/issues/44
    // category_id is coming as a string, not an int
    const categoryId = parseInt(import_task.category_id, 10);

    if (import_task) {
      queryClient.setQueriesData(['categories'], data => produce(data, draft => {
        const selectedCategory = Array.isArray(data) ? draft.find(category => category.id === categoryId) : (data.id === categoryId && draft);
        
        if (selectedCategory) {
          if (selectedCategory.unchecked_import_tasks) {
            selectedCategory.unchecked_import_tasks.unshift(import_task);
          }
          else {
            selectedCategory.unchecked_import_tasks = [import_task];
          }
        }
      }));
    }
  }

  return {
    updateLead         : useMutation({ mutationFn: updateLead, onSuccess: onUpdateSuccess }),
    updateLeadAsync    : useMutation({ mutationFn: updateLead, onMutate: onMutateLead }),
    undoDeleteMutation : useMutation({ mutationFn: undoDeleteLead, onMutate: onUndoDelete }),
    insertMutation     : useMutation({ mutationFn: addLead, onSuccess: onAddLead }),
    deleteMutation     : useMutation({ mutationFn: deleteLead, onMutate: onDelete }),
    bulkDeleteMutation : useMutation({ mutationFn: deleteBulk, onMutate: onBulkDelete }),
    importMutation     : useMutation({ mutationFn: importLeads, onSuccess: onImport }),
  }
}
