import { useContext, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query";
import AppContext from "../../utils/AppContext";
import { api } from "../../utils/api";

import Button from "../../common/Button/Button";
import UserAvatar from "../../common/User/UserAvatar";

import { ReactComponent as ErrorIcon } from "../../assets/icons_svg/error-lined.svg";

import './UserInvitation.scss';

export default function UserInvitation() {
  const [state] = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const [processing, setProcessing] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate(); 
  const token = searchParams.get('token');

  const queryFn = () => {
    return api.get(`/invitations/?token=${token}`)?.then(res => res.data);
  }

  const { status, data: invitationData } = useQuery({ queryKey: ['user-roles', token], queryFn, retry: 1 });

  const redirectURL = useMemo(() => {
    return encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search);
  }, []);

  const { currentUser } = state;

  const onAcceptInvite = async () => {
    setProcessing(true);
    setError(false);

    try {
      await api.post('/invitations/accept/', { invitation_token: token });
      navigate('/');
    }
    catch {
      setError(true);
      setProcessing(false);
    }

  }

  const renderInvitation = () => {
    if (invitationData) {
      return (
        <>
          <UserAvatar user={{ id: invitationData.organization_id, first_name: invitationData.organization_name }} />
          <h3>You have been invited to join the organization<br /><strong>{ invitationData.organization_name }</strong></h3>
          { currentUser ? <Button variant='primary' onClick={onAcceptInvite} loading={processing}>Accept Invitation</Button> : <Link className="__button __button-primary" to={`/login?redirect_to=${redirectURL}`}>Log in to continue</Link> }
          { currentUser
          ? <div className='logged-as'>Logged in as: { currentUser.email }</div>
          : <p className='create-account'><Link to={`/signup?redirect_to=${redirectURL}`}>Create an account</Link></p>
          }
          { error && <div className='error-accept'>There was a problem accepting this invitation. Try again or tell your organization to contact support</div> }
        </>
      );
    }

    if (status === 'error') {
      return (
        <>
          <ErrorIcon className="error-icon" />
          <h3>This invitation link is invalid or has expired.</h3>
          <p className="description">Get in touch with an administrator of the organization to reset or create a new invitation.</p>
        </>
      );
    }

    // status === loading
    return (
      <>
        <div className="loading-avatar" />
        <div className="loading-text" />
        <div className="loading-text" />
      </>
    );
  }
  
  return (
    <section className='__user-invitation'>
      <h1>Precious Leads</h1>
      <div className="invitation-content">
        { renderInvitation() }
      </div>
    </section>
  );
}
